<template>
	<div>
		<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
			<el-form-item label="消息类型" prop="classname">
				<el-input  v-model="ruleForm.classname" disabled></el-input>
			</el-form-item>
			<el-form-item label="标签说明" prop="topclass">
			    <template>
					<el-checkbox-group v-model="checkList">
						<el-checkbox label="复选框 A"></el-checkbox>
						<el-checkbox label="复选框 B"></el-checkbox>
						<el-checkbox label="复选框 C"></el-checkbox>
						<el-checkbox label="复选框 A"></el-checkbox>
						<el-checkbox label="复选框 B"></el-checkbox>
						<el-checkbox label="复选框 C"></el-checkbox>
						<el-checkbox label="复选框 A"></el-checkbox>
						<el-checkbox label="复选框 B"></el-checkbox>
						<el-checkbox label="复选框 C"></el-checkbox>
					</el-checkbox-group>
			    </template>
			</el-form-item>
			<el-form-item label="站内信标题" prop="numbitem">
				<el-input  v-model="ruleForm.numbitem" ></el-input>
			</el-form-item>
			<el-form-item label="内容模板" prop="bbbb">
				<el-input  v-model="ruleForm.bbbb" type="textarea"></el-input>
				内容不能为空，长度限制在1-300个字符之间
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import * as API_goods from '@/api/goods'
	import * as API_goodsCategory from '@/api/goodsCategory'
	import { UE } from '@/components'
	import { RegExp } from '@/../ui-utils'
	import Sortable from 'sortablejs'
	export default {
		name: 'goodsPublish',
		components: {
			UE
		},
		data() {
			return {
				checkList: ['选中且禁用'],
				ruleForm: {
					classname:'手机短信',
					topclass:'',
					Attrclass:[''],
					numbitem:'',
					paixu:'',
					aaaa:'',
					bbbb:''
				},
				rules: {
					classname: [
					     { required: true, message: '请输入分类名称', trigger: 'change' }
					],
					numbitem: [
					     { required: true, message: '请输入分类名称', trigger: 'change' }
					],
					bbbb: [
					     { required: true, message: '请输入分类名称', trigger: 'change' }
					]
				}
			};
		},
		mounted() {

		},
		methods: {
			submitForm(formName) {
			    this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
			        }
			    });
			},
			addarr(){
				this.ruleForm.Attrclass.push('')
			},
			delarr(v){
				this.ruleForm.Attrclass.splice(v,1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	/*选择商品品类*/
	.demo-ruleForm {
		margin: 0 auto;
		margin-top: 100px;
		width: 800px;
		.el-input {
			width: 30%;
		}
		.balck{
			margin-right:100px ;
		}
	}
</style>
<style scoped>

</style>
